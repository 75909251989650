@use '../../styles/yr' as *;

$logo: var(--color-logo-primary-bg-rest);
$logo-hover: var(--color-logo-primary-bg-hover);

// We use order since we want the source order to not be the same as the visual order
// This is for a11y reasons, where it makes more sense to have the location name after the Menu and Search buttons
// See: https://nrknyemedier.atlassian.net/browse/YR-3601
$logo-order: 1;
$spacer-order: 2;
$location-order: 3;
$toolbar-order: 4;

.page-header {
  height: size(9.5);
  position: relative;
  display: flex;

  @include mq-lt($mq-675) {
    margin: 0 size(2);
  }
}

.page-header__logo {
  @include round;

  order: $logo-order;
  color: $logo;
  display: block;
  margin: size(2) 0;
  position: relative;
  text-decoration: none;
  transition: none;

  // We use flex-shrink: 0 to make sure the logo is never shrinked or pushed out of view by long location names
  flex-shrink: 0;

  .f-no-touch &:hover,
  .f-no-touch &:active,
  .no-js &:hover,
  &:focus {
    background-color: transparent;
    color: $logo-hover;
  }
}

.page-header__spacer {
  order: $spacer-order;
  background-color: transparent;
  border: 0;
  flex: 1;
  font-weight: $typography-font-weight-normal;
  margin: 0;
  padding: 0;
  text-align: left;
  top: 1em;
}

.page-header__location {
  order: $location-order;
  position: relative;
  font-weight: $typography-font-weight-normal;
  text-align: left;
  flex: 1;
  margin: size(2) 0;
  padding-left: size(2);

  // By default flex items are given min-width: auto
  // We set it to 0 here to avoid the flex item growing, since we want text-overflow: ellipsis on the child
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.page-header__location-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.page-header__location-button {
  color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-header__location-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-header__location-star {
  height: size(2);
  width: size(2);
  margin-left: size(0.5);

  // The favourite star should allways stay the same size
  flex-shrink: 0;
}

// Hide star on browser that does not pass cut the mustard, since these can't add location to favourites
.no-js .page-header__location-star {
  display: none;
}

.page-header__location-star--empty {
  color: var(--color-stroke-subtle);
}

.page-header__location-star--filled {
  color: var(--color-fill-selected);
}

.page-header__location-details {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;

  // Prevent the element from shrinking and clipping the bottom of
  // text descenders.
  flex-shrink: 0;

  .no-js & {
    position: static;
  }

  bottom: size(0.25);
}

.page-header__search {
  position: absolute;
  z-index: $z-index-search-suggestions;

  // Vertically align search on bigger screens
  @include mq-gt($mq-675) {
    top: 50%;
    transform: translateY(-50%);
    left: size(6);

    // To make the right side of the search container overlap the right side
    // of the search button we use the custom property `--search-input-position-right`
    // which gets calculated in `PageHeader.tsx`when the search input is opened.
    // For browsers that don't support custom properties the right side of the
    // search container reaches the right side of the page header instead.
    right: 0;
    right: var(--search-input-position-right);
  }
}

.page-header__toolbar {
  order: $toolbar-order;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: size(2) 0;
}

// Hide no-js buttons in js mode
.js .page-header__toolbar-button[data-mode='no-js'] {
  display: none;
}

// Hide js buttons in no-js mode
.no-js .page-header__toolbar-button[data-mode='js'] {
  display: none;
}

// Don't display search button in no-js mode on pages where there is a static search input
.no-js .page-header__toolbar-button[data-page-id='home'],
.no-js .page-header__toolbar-button[data-page-id='search'] {
  display: none;
}

.page-header__toolbar-button {
  // The search button is actually a <label /> so we need to set the cursor here.
  cursor: pointer;

  margin-left: size(2);

  @include mq-lt($mq-675) {
    &[data-mode='js'] {
      display: none;
    }
  }
}

.page-header__toolbar-icon-button {
  align-items: center;
  color: var(--color-text-subtle);
  display: flex;
  height: size(4);
  justify-content: center;
  margin-left: size(1);
  width: size(4);

  // The search and menu buttons should only be visible on small screens
  @include mq-gte($mq-675) {
    display: none;
  }

  // The search and menu buttons do nothing in no-js mode so we hide them
  .no-js & {
    display: none;
  }
}
