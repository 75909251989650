@use "sass:math";
@use '../../styles/yr' as *;

.badge {
  display: inline-block;
  position: relative;
  z-index: 0;
  white-space: nowrap;

  // Use the after pseudo element to give the badge its colored background
  // without increasing the badge's height.
  &:after {
    border-radius: size(0.5);
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: 0;

    // Render the pseudo-element behind the element content
    z-index: -1;
  }
}

.badge--small {
  $height: rem(21px);
  line-height: $text-5-line-height;

  padding: 0 size(0.5);

  &:after {
    $negative-offset: -#{math.div($height - $text-5-line-height, 2)};
    top: $negative-offset;
    bottom: $negative-offset;
  }
}

.badge--default {
  $height: rem(34px);
  line-height: $text-4-line-height;

  padding: 0 size(1);

  &:after {
    $negative-offset: -#{math.div($height - $text-4-line-height, 2)};
    top: $negative-offset;
    bottom: $negative-offset;
  }
}

.badge--cloud-cover:after {
  background-color: var(--color-background-subtle-gray);
}

.badge--new {
  color: var(--color-text-inverse);
  &:after {
    background-color: var(--color-fill-accent-core-blue);
  }
}

.badge--precipitation:after {
  background-color: var(--color-background-subtle-core-blue);
}

.badge--temperature-warm:after {
  background-color: var(--color-background-subtle-red);
}

.badge--temperature-cold:after {
  background-color: var(--color-background-subtle-core-blue);
}

.badge--wind:after {
  background-color: var(--color-background-subtle-purple);
}

.badge--snow-depth:after {
  background-color: var(--color-background-subtle-warm-mint);
}

.badge--air-quality-low {
  color: var(--color-text-darken-cool-green);
  &:after {
    background-color: var(--color-background-subtle-cool-green);
  }
}

.badge--air-quality-moderate {
  color: var(--color-text-darken-yellow);
  &:after {
    background-color: var(--color-background-subtle-yellow);
  }
}

.badge--air-quality-severe {
  color: var(--color-text-darken-red);
  &:after {
    background-color: var(--color-background-subtle-red);
  }
}

.badge--air-quality-extreme {
  color: var(--color-text-darken-purple);
  &:after {
    background-color: var(--color-background-subtle-purple);
  }
}

.badge--pollen-low {
  color: var(--color-text-darken-cool-green);
  &:after {
    background-color: var(--color-background-subtle-cool-green);
  }
}

.badge--pollen-moderate {
  color: var(--color-text-darken-yellow);
  &:after {
    background-color: var(--color-background-subtle-yellow);
  }
}

.badge--pollen-severe {
  color: var(--color-text-darken-cool-orange);
  &:after {
    background-color: var(--color-background-subtle-cool-orange);
  }
}

.badge--pollen-extreme {
  color: var(--color-text-darken-red);
  &:after {
    background-color: var(--color-background-subtle-red);
  }
}

.badge--uv-none {
  color: var(--color-text-base);
  &:after {
    background-color: var(--color-background-subtle-gray);
  }
}

.badge--uv-low {
  color: var(--color-text-base);
  &:after {
    background-color: var(--color-background-subtle-cool-green);
  }
}

.badge--uv-moderate {
  color: var(--color-text-darken-yellow);
  &:after {
    background-color: var(--color-background-subtle-yellow);
  }
}

.badge--uv-high {
  color: var(--color-text-darken-cool-orange);
  &:after {
    background-color: var(--color-background-subtle-cool-orange);
  }
}

.badge--uv-severe {
  color: var(--color-text-darken-red);
  &:after {
    background-color: var(--color-background-subtle-red);
  }
}

.badge--uv-extreme {
  color: var(--color-text-darken-purple);
  &:after {
    background-color: var(--color-background-subtle-purple);
  }
}

.badge--aurora-forecast-no-activity {
  color: var(--color-text-base);
  &:after {
    background-color: var(--color-background-subtle-gray);
  }
}

.badge--aurora-forecast-low-activity {
  color: var(--color-text-base);
  &:after {
    background-color: var(--color-background-subtle-cool-mint);
  }
}

.badge--aurora-forecast-high-activity {
  color: var(--color-text-base);
  &:after {
    background-color: var(--color-background-subtle-purple);
  }
}

.badge--twenty-one-day-forecast-frost-low:after {
  background-color: var(--color-background-muted-cool-green);
}

.badge--twenty-one-day-forecast-frost-moderate:after {
  background-color: var(--color-stroke-muted-cool-orange);
}

.badge--twenty-one-day-forecast-frost-high:after {
  background-color: var(--color-background-muted-red);
}
