@use '../../styles/yr' as *;

.overlay-panel__content {
  position: fixed;

  .overlay-panel--fullscreen & {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.overlay-panel__backdrop {
  @include backdrop;
  background-color: transparent;
}

.overlay-panel__animation-container {
  display: flex;
  opacity: 0;

  // Render in front of the backdrop
  position: relative;
  z-index: 1;

  .overlay-panel--fullscreen & {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.overlay-panel__animation-container--will-animate {
  transform: scale(0, 0);
}

.overlay-panel__animation-container--is-animating {
  opacity: 1;
  transform: scale(1, 1);
  transition: transform $ui-transition;
}

.overlay-panel__dialog-wrapper {
  // Center the dialog horizontally and vertically
  margin: auto;

  // Make it possible to click on the backdrop
  pointer-events: none;
}

.overlay-panel__dialog {
  background-color: var(--color-background-elevated);
  border-radius: size(1);
  border: 1px solid var(--color-stroke-elevated);
  box-shadow: $theme-box-shadow-elevated;
  margin: auto;
  position: relative;
  text-align: left;
  z-index: $z-index-popover;

  // Automatically break long words
  // See https://justmarkup.com/log/2015/07/dealing-with-long-words-in-css/
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;

  // Make it possible to click in the dialog
  pointer-events: all;

  @include mq-gt(374px) {
    margin: 0.5rem;
  }

  @include mq-gt(414px) {
    margin: 1rem;
  }
}

.overlay-panel__children {
  opacity: 0;
  transition: opacity 200ms ease-in 50ms;

  .overlay-panel__animation-container--is-animating & {
    opacity: 1;
  }
}
